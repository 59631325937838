const cognitoSettings = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_IgV7ewU76",
  APP_CLIENT_ID: "1k4me9p5dsjchhi1i51s2k4ros",
  IDENTITY_POOL_ID: "us-east-1:ff6691a2-5a0d-496f-813f-caa92fa7614f",
}

const dev = {
  cognito: cognitoSettings,
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://dev.api.instacaptcha.com"
  },
  s3: {
    BUCKET: "kdaspics2app-dev",
    REGION: "us-east-1",
  }
}

const prod =  {
  cognito: cognitoSettings,
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.instacaptcha.com",
  },
  s3: {
    BUCKET: "kdaspics2app",
    REGION: "us-east-1",
  }
}

export const APINAME="photos"

export default process.env.NODE_ENV === "development" ? dev : prod;
